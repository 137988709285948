<template>
    <Form @submit="onSubmit" class="submitFrom row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <Field type="text" class="form-control" name="Full Name" placeholder="Full Name" v-model="form.fullName"
                :rules="{ required: true, onlyAlphabet: true }"   @keydown = "onInputString($event,'fullName')"/>
                <ErrorMessage class="text-danger" name="Full Name" />
                <!-- <input type="text" class="form-control" placeholder="Full Name"> -->
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <Field type="text" class="form-control" name="Company Name" placeholder="Company Name"
                    v-model="form.companyName" />
                <!-- <input type="text" class="form-control" placeholder="Last Name"> -->
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <Field type="text" class="form-control" name="Email" placeholder="Email" v-model="form.email"
                    rules="required|email" />
                <ErrorMessage class="text-danger" name="Email" />
                <!-- <input type="text" class="form-control" placeholder="Email"> --> 
            </div>
        </div>
        <div class="col-12">
            <div class="belowCustomInput form-group position-relative">
                <Field
                    v-slot="{ field }"
                    name="Phone Number"
                    :rules="'required|min:7|max:15'" 
                    v-model="form.mobile" >
                    <vue-tel-input
                    v-bind="field"
                    :auto-format = "false"
                    v-model="form.mobile"
                    @country-changed="GetCode"
                    @blur="onMobileInput"
                    @keyup="onMobileInput"
                    :inputOptions="options"
                    :validCharactersOnly = "true"
                    :custom-validate="form.mobile!=''? new RegExp(/^[0-9]+$/):false"
                    :onlyCountries ="getAllowCountry()"
                    ref="phone"
                    inputmode="numeric"
                    ></vue-tel-input>
                </Field>
                <ErrorMessage class="text-danger" name="Phone Number" />
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <Field as="textarea" class="form-control" name="Message" placeholder="Enter message here"
                    v-model="form.message" rules="required|maxChar:500"/>
                <ErrorMessage class="text-danger" name="Message" />
                <!-- <textarea type="text" class="form-control" placeholder="Enter Message here"></textarea> -->
            </div>
        </div>
        <div class="col-12">
            <button type="submit" class="button zulu_btn newGradient">Send</button>
        </div>
    </Form>

</template>
<script>
import { myStore } from "@/store/pinia-store.ts";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            form: {
                fullName: '',
                companyName: '',
                phoneCountryCode: '',
                phoneNumber: '',
                message: '',
                email: '',
                mobile:''
            },
            selected_phone_country:null,
            options: { placeholder: this.$t('changes.text49') },
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods: {
        getAllowCountry(){
          return Object.values(this.store.countriesList).map((val) => val.isoCode) || [];
      },
        GetCode(e) {
            this.selected_phone_country = e;
            this.form.phoneCountryCode = e.dialCode;
        },
        onMobileInput(){
            if(parseInt(this.form.mobile) == 0){
                this.form.mobile = '';
            }
        },

        onSubmit(values, { resetForm }) {
            let payload = {}
            Object.entries(this.form).map(([key, value]) => {
                if (value) payload[key] = value
            })
            if(this.form.phonecode){
                payload['phoneCountryCode'] = this.form.phonecode;
            }
            if(this.form.mobile){
                payload['phoneNumber'] = this.form.mobile;
                delete payload.mobile;
            }
            if (!payload.phoneNumber || !payload.phoneCountryCode) {
                delete payload.phoneCountryCode
                delete payload.phoneNumber
            }
            this.store.contactUsForm(payload).then(() => {
                resetForm();
            }).catch((error) => {
                console.log(error)
            })
        },
        onInputString(e,field_name) {
            const keyCode = e.keyCode || e.which;
            if(!this.COMMON.validateIsString(keyCode)){
                e.preventDefault();
            }
            else{
                this.form[field_name] = e.target.value;      
            }
        },

    },
}

</script>