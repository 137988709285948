<template>
    <section class="blockElement space brokeragePage">
        <div class="container max-1440">
            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <h1>Rewrite the Rules of <span class="secondary">Brokerage</span></h1>
                    <p class="space pe-md-5">Tap into the $85 billion social trading revolution. With ZuluTrade, empower
                        your traders and watch your profits multiply.</p>
                    <a href="https://calendly.com/partnerships-zulutrade/30min?month=2024-09" target="_blank"
                        class="d-inline-flex align-items-center button zulu_btn  newGradient">
                        <span class="icns me-2"><img src="/assets/images/broker/calendar.svg" alt="Icon"></span>
                        Book a Meeting with us</a>
                </div>
                <div class="col-12 col-md-6">
                    <v-lazy-image width="632" src="/assets/images/broker/imgBanner.png" alt="Icon" />
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement customSpace bg-white ourPartners overflow-hidden">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="placeTitle text-center">
                        <h2>Our <span class="fill-bg">Partners</span></h2>
                        <p>Discover your perfect match using more than 40 filters. Rank Leaders based on your
                            preferences.</p>
                    </div>
                </div>
                <div class="col-12">
                    <carousel class="mt-0 text-start" :settings="carousleSettings" :wrapAround="true"
                        :breakpoints="breakpoints">
                        <slide :key="1">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-1-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-1.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="2">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-2-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-2.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="3">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-3-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-3.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="4">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-4-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-4.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="5">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-5-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-5.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="6">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-6-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-6.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="7">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-7-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-7.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="8">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-8-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-8.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>
                        <slide :key="9">
                            <div class="carousel__item">
                                <span class="hoverBroker">
                                    <v-lazy-image width="130" height="36px" class="d-block mx-auto"
                                        src="/assets/images/broker/slide-9-hover.svg" alt="Icon" />
                                </span>
                                <span class="brokerIcon">
                                    <v-lazy-image width="130" src="/assets/images/broker/slide-9.svg" alt="Icon" />
                                </span>
                            </div>
                        </slide>

                    </carousel>
                    <div class="midTradeLogo d-flex justify-content-center">
                        <v-lazy-image width="632" src="/assets/images/broker/shadowZulu.svg" alt="Icon" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement customSpace bg-white whyPartner overflow-hidden">
        <div class="container max-1440">
            <div class="row">
                <div class="col-12 mb-md-5 mb-3">
                    <div class="placeTitle text-center">
                        <h2>Why Partner With <span class="fill-bg">ZuluTrade?</span></h2>
                        <p>Retention and Acquisition: the perfect formula for sustained success.</p>
                    </div>
                </div>
                <div class="col-12 col-lg-7 mb-3 mb-lg-0">
                    <div class="row g-4">
                        <div class="col-12 col-md-6">
                            <div class="card one">
                                <span class="Icon">
                                    <span class="getIDIcon getIDIcon1"></span>
                                </span>
                                <h5 class="f-20">Boost Trading Volume</h5>
                                <p class="mb-0">Increase trading volume by <span class="secondary">33%</span> and tap
                                    into new income streams.</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="card two">
                                <span class="Icon">
                                    <span class="getIDIcon getIDIcon2"></span>
                                </span>
                                <h5 class="f-20">Enhance Client Retention</h5>
                                <p class="mb-0">Reduce churn rate by <span class="secondary">15%</span> with our
                                    platform.</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="card three">
                                <span class="Icon">
                                    <span class="getIDIcon getIDIcon3"></span>
                                </span>
                                <h5 class="f-20">Expand Your Offerings</h5>
                                <p class="mb-0">Attract new traders with cutting-edge social and copy trading features
                                    by <span class="secondary">+12%</span>.</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="card four">
                                <span class="Icon">
                                    <span class="getIDIcon getIDIcon4"></span>
                                </span>
                                <h5 class="f-20">Lower Acquisition Cost</h5>
                                <p class="mb-0">Cut client acquisition expenses by up to <span
                                        class="secondary">25%</span>.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5">
                    <div class="card five p-0 elevateBrand">
                        <div class="px-4 pt-4">
                            <span class="Icon">
                                <v-lazy-image width="50" src="/assets/images/broker/platfrom.svg" alt="Icon" />
                            </span>
                            <h5 class="f-20 text-white">Elevate Your Brand</h5>
                            <p class="mb-0 text-white">Stand out with innovative copy trading technology and a wider
                                community.</p>
                        </div>
                        <v-lazy-image width="570" src="/assets/images/broker/brandGroup.png" alt="Icon" />
                    </div>

                </div>
            </div>
        </div>
    </section>
    <section class="blockElement customSpace pb-4 howWorks overflow-hidden">
        <div class="container max-1440 position-relative">
            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <span class="afterShadow"><v-lazy-image width="644" src="/assets/images/broker/howitWork.png"
                            alt="How it Works" /></span>
                </div>
                <div class="col-12 col-md-6">
                    <div class="placeTitle">
                        <h2>How it <span class="fill-bg">Works?</span></h2>
                        <p>Experience a seamless partnership journey designed for your success.</p>
                        <ul class="listSetup">
                            <li><span class="count">1</span> Technical Setup <span class="secondary ms-1">(1-7
                                    Days)</span></li>
                            <li><span class="count">2</span> Launch Preparation <span class="secondary ms-1">(1
                                    Week)</span></li>
                            <li><span class="count">3</span> Go <span class="secondary ms-1">Live</span></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section class="blockElement customSpace bg-white industryProven overflow-hidden">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mb-md-5 mb-3">
                    <div class="placeTitle text-center">
                        <h2>Industry Proven <span class="fill-bg">Technology</span></h2>
                        <p><strong>Manager API</strong> The Engine for Automated Copy Trading</p>
                    </div>
                </div>
                <div class="col-12">
                    <animationBlock />
                </div>
            </div>
        </div>
        <div class="container max-1440">
            <div class="row g-4">
                <div class="col-12 col-md-4">
                    <div class="cardBoxed">
                        <h5 class="f-20 d-flex align-items-center"><v-lazy-image width="32" class="me-2"
                                src="/assets/images/broker/dtIcon-1.svg" alt="Icon" /> Direct Integration</h5>
                        <p class="mb-0">Quickly and easily install on your existing infrastructure.</p>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="cardBoxed">
                        <h5 class="f-20 d-flex align-items-center"><v-lazy-image width="32" class="me-2"
                                src="/assets/images/broker/dtIcon-2.svg" alt="Icon" /> Automated Execution</h5>
                        <p class="mb-0">Orchestrate trades from our proven leaders directly to client accounts.</p>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="cardBoxed">
                        <h5 class="f-20 d-flex align-items-center"><v-lazy-image width="32" class="me-2"
                                src="/assets/images/broker/dtIcon-3.svg" alt="Icon" /> Secure Operations</h5>
                        <p class="mb-0">Built with industry-standard security protocols.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement customSpace howWorks ourSolutions overflow-hidden">
        <div class="container max-1440 position-relative">
            <div class="row g-4">
                <div class="col-12">
                    <div class="placeTitle text-center mb-3 mb-md-5 pb-md-2">
                        <h2>Our B2B <span class="fill-bg">Solutions</span></h2>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="cardBoxed d-flex flex-column justify-content-between p-0">
                        <div class="px-3 pt-4 pb-0">
                            <h5 class="f-20"> API Integration</h5>
                            <p class="mb-0">Seamlessly incorporate our technology into your existing trading
                                infrastructure within one week,ensuring minimal disruption.</p>
                        </div>
                        <v-lazy-image class="d-block" src="/assets/images/broker/sol-1.png" alt="Icon" />
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="cardBoxed d-flex flex-column justify-content-between p-0">
                        <div class="px-3 pt-4 pb-0">
                            <h5 class="f-20"> Custom Development</h5>
                            <p class="mb-0">We tailor our solutions specifically to your business needs, providing you
                                with the flexibility to achieve your goals.</p>
                        </div>
                        <v-lazy-image class="d-block" src="/assets/images/broker/sol-2.png" alt="Icon" />
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="cardBoxed d-flex flex-column justify-content-between p-0">
                        <div class="px-3 pt-4 pb-0">
                            <h5 class="f-20"> Marketing and Sales Support</h5>
                            <p class="mb-0">We offer you the benefit of our expertise in both trader acquisition and
                                retention, helping you to grow and maintain your customer base effectively</p>
                        </div>
                        <v-lazy-image class="d-block" src="/assets/images/broker/sol-3.png" alt="Icon" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement customSpace pb-0 bg-white worldMap overflow-hidden">
        <div class="container mb-3 mb-md-5">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="placeTitle text-center mb-3 mb-md-5">
                        <h2>About <span class="fill-bg">ZuluTrade</span></h2>
                    </div>
                </div>
                <div class="col-12 col-md-8 text-center">
                    <p>We didn't just join the copy trading game - we invented it. Born in 2007, we've been the
                        undisputed heavyweight champ for 17 years and counting</p>
                    <p>Our secret sauce? A melting pot of diverse trading minds, cutting-edge tech, and an ecosystem so
                        robust it makes Wall Street jealous. We're not just helping traders invest - we're
                        revolutionizing wealth management for the smart money crowd.</p>
                </div>
            </div>
        </div>
        <div class="mapAdd">
            <v-lazy-image src="/assets/images/broker/wordMap.png" alt="Map" />
        </div>
    </section>
    <section class="blockElement customSpace bg-white contactBrokerage overflow-hidden">
        <div class="container max-1440 position-relative">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="placeTitle text-center mb-3 mb-md-5">
                        <h2>Ready to Transform Your <span class="fill-bg">Brokerage?</span></h2>
                        <p>Join the leading brokers who have already partnered with ZuluTrade. Let's grow together.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container max-1440 position-relative cardHolder">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 d-none d-md-block">
                    <v-lazy-image class="d-block" width="500" src="/assets/images/broker/mobForm.png" alt="Icon" />
                </div>
                <div class="col-12 col-md-6 pb-md-4 mt-n3">
                    <div class="placeTitle mb-1 mb-md-1">
                        <h2>Contact Us</h2>
                    </div>
                    <ContactUs />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import { Carousel, Slide } from 'vue3-carousel'
import animationBlock from "./animationBlock.vue";
import ContactUs from '@/components/shared/contact-us'

export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            carousleSettings: {
                itemsToShow: 7,
                itemsToScroll: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                320: {
                    itemsToShow: 1,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                },
                991: {
                    itemsToShow: 2.95,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                    autoplay: 3000,
                    transition: "800",
                },
                1200: {
                    itemsToShow: 3.95,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                    autoplay: 3000,
                    transition: "800",
                },
                1487: {
                    itemsToShow: 4.95,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                    autoplay: 3000,
                    transition: "800",
                },
                1700: {
                    itemsToShow: 5.95,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                    autoplay: 3000,
                    transition: "800",
                },
            },
        }
    },
    components: {
        Carousel, Slide,
        animationBlock,
        ContactUs
    },
}
</script>